import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Login',
    component: () => import('../views/Login.vue')
  },
  {
    path: '/dashboard',
    name: 'Home',
    component: () => import('../views/Home.vue')
  },
  //Orders
  {
    path: '/orders',
    name: 'Orders',
    component: () => import('../views/order/Orders.vue')
  },
  {
    path: '/order/add',
    name: 'CreateOrder',
    component: () => import('../views/order/CreateOrder.vue')
  },
  {
    path: '/order/:id',
    name: 'Order',
    component: () => import('../views/order/Order.vue')
  },

  //Products
  {
    path: '/products',
    name: 'Products',
    component: () => import('../views/product/Products.vue')
  },
  {
    path: '/product/add',
    name: 'AddProduct',
    component: () => import('../views/product/AddProduct.vue')
  },
  {
    path: '/product/edit/:id',
    name: 'EditProduct',
    component: () => import('../views/product/EditProduct.vue')
  },
  {
    path: '/categories',
    name: 'Categories',
    component: () => import('../views/product/Categories.vue')
  },
  {
    path: '/categorie/add',
    name: 'AddCategorie',
    component: () => import('../views/product/AddCategorie.vue')
  },
  {
    path: '/categorie/edit/:id',
    name: 'EditCategorie',
    component: () => import('../views/product/EditCategorie.vue')
  },
  {
    path: '/barcodes',
    name: 'Barcode',
    component: () => import('../views/product/Barcode.vue')
  },

  //Document
  {
    path: '/documents',
    name: 'Documents',
    component: () => import('../views/document/Documents.vue')
  },
  {
    path: '/document/add',
    name: 'CreateDocument',
    component: () => import('../views/document/CreateDocument.vue')
  },
  //Customers
  {
    path: '/customers',
    name: 'Customers',
    component: () => import('../views/customer/Customers.vue')
  },
  {
    path: '/customer/:id',
    name: 'Customer',
    component: () => import('../views/customer/Customer.vue')
  },
  //Delivery Orders
  {
    path: '/delivery-invoices',
    name: 'DeliveryInvoices',
    component: () => import('../views/delivery/DeliveryInvoices.vue')
  },
  {
    path: '/delivery-invoices/add',
    name: 'CreateDeliveryInvoices',
    component: () => import('../views/delivery/CreateInvoice.vue')
  },
  {
    path: '/delivery-invoice/:id',
    name: 'DeliveryInvoice',
    component: () => import('../views/delivery/Invoice.vue')
  },
  //Reports
  {
    path: '/report/popular',
    name: 'MorePopularReport',
    component: () => import('../views/report/MostPopular.vue')
  },
  //Settings
  {
    path: '/settings/website',
    name: 'WebsiteSettings',
    component: () => import('../views/setting/WebsiteSettings.vue')
  },
  {
    path: '/settings/account',
    name: 'AccountSettings',
    component: () => import('../views/setting/AccountSettings.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
