const state = {
    currentInvoice: null,
  };
  
  const mutations = {
    updateInvoice(nextState, invoice){
      nextState.currentInvoice = invoice;
    },
  };
  
  const actions =  {
    setCurrentInvoice: (context, invoice) => context.commit('updateInvoice', invoice),
    removeCurrentInvoice: (context) => context.commit('updateInvoice', null),
  };
  
  export default {
    state,
    actions,
    mutations,
  };
  